<template>
  <footer
    v-show="isVisible && isFooterVisible"
    :class="[$style.footer, 'js-site-scroll-adapt', { [$style.leaving]: isViewLeaving }]"
  >
    <div :class="$style.footerInner">
      <div :class="$style.footerColumns">
        <div :class="$style.footerColumn">
          <Heading
            :class="[$style.footerColumnHead, $style.footerColumnHeadLogo]"
            :tag="HEADINGS.h4"
          >
            <NuxtLink :class="$style.footerHeadLink" :to="localePath(ROUTE_NAMES.home)">
              <IconAccesImpot :class="$style.iconAccesImpot" />
            </NuxtLink>
          </Heading>
          <div :class="$style.footerLinkWrap">
            <NuxtLink
              :class="[$style.footerLink, { [$style.activeLink]: isAccountSection }]"
              :to="localePath(ROUTE_NAMES.account.index)"
              v-text="$t('userAccount')"
            />
          </div>
          <div :class="$style.footerLinkWrap">
            <a :class="$style.footerLink" @click="showOverlayCookieConsent">{{
              $t('privacySettings')
            }}</a>
          </div>
          <div :class="$style.footerLinkWrap">
            <NuxtLink
              :class="$style.footerLink"
              :to="linkToHomeFeatures"
              :event="''"
              @click.native.prevent="goToHomeSection(linkToHomeFeatures)"
            >
              <LineBreakResolver :text="$t('howItWorks')" />
            </NuxtLink>
          </div>
          <div :class="$style.footerLinkWrap">
            <NuxtLink
              :class="$style.footerLink"
              :to="linkToHomePrices"
              :event="''"
              @click.native.prevent="goToHomeSection(linkToHomePrices)"
            >
              <LineBreakResolver :text="$t('pricing')" />
            </NuxtLink>
          </div>
          <div :class="$style.footerLinkWrap">
            <NuxtLink
              :class="$style.footerLink"
              :to="localePath(ROUTE_NAMES.faq.index)"
              v-text="$t('faq')"
            />
          </div>
        </div>
        <div :class="$style.footerColumn">
          <Heading :class="$style.footerColumnHead" :tag="HEADINGS.h4">
            {{ $t('languageHeading') }}
          </Heading>
          <div :class="$style.footerLinkWrap">
            <NuxtLink
              :class="[$style.footerLink, { [$style.activeLink]: $i18n.locale === 'fr' }]"
              :to="switchLocalePath('fr')"
              v-text="$t('language.fr')"
            />
          </div>
          <div :class="$style.footerLinkWrap">
            <NuxtLink
              :class="[$style.footerLink, { [$style.activeLink]: $i18n.locale === 'en' }]"
              :to="switchLocalePath('en')"
              v-text="$t('language.en')"
            />
          </div>
        </div>
        <div :class="$style.footerColumn">
          <Heading :class="$style.footerColumnHead" :tag="HEADINGS.h4">
            <NuxtLink :class="$style.footerHeadLink" :to="localePath(ROUTE_NAMES.contact)">
              {{ $t('contactUs') }}
            </NuxtLink>
          </Heading>
          <div :class="$style.footerLinkWrap">
            <a
              :class="$style.footerLink"
              :href="`mailto:${INFO_EMAIL_ADDRESS}`"
              @click="trackContactClick(TRACK_TARGETS.email)"
              >{{ INFO_EMAIL_ADDRESS }}</a
            >
          </div>
          <div :class="$style.footerItem" style="opacity: 0.5">
            <NuxtLink :class="$style.footerLink" :to="localePath(ROUTE_NAMES.contact)">
              <span class="u-nobr">{{ $t('phone.byPhone') }}</span>
              <br />
              <span class="u-nobr">{{ $t('phone.openingHours') }}</span>
              {{ ' ' }}
              <span class="u-nobr">{{ $t('phone.openingDays') }}</span>
            </NuxtLink>
          </div>
          <div :class="$style.footerItem">{{ $t('temporarilyUnavailable') }}</div>
        </div>
        <a :class="$style.iconFacebookLink" :href="FACEBOOK_URL" target="_blank"
          ><IconFacebook :class="$style.iconFacebook"
        /></a>
      </div>
      <div :class="$style.copyright">&copy; {{ $t('copyright') }}</div>
    </div>
  </footer>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { ROUTE_NAMES } from '@/constants/route-names'
import { EventBus, EVENTS } from '@/services/event-bus'
import { track, TRACK_CATEGORIES, TRACK_CONTEXTS } from '@/services/tracking'
import { goToHomeSection } from '@/helpers/url'
import LineBreakResolver from '@/components/LineBreakResolver.vue'
import Heading, { HEADINGS } from '@/components/Heading/Heading.vue'
import IconAccesImpot from '@/components/_icons/IconAccesImpot.svg'
import IconFacebook from '@/components/_icons/IconFacebook.svg'

const INFO_EMAIL_ADDRESS = 'info@acces-impot.com'
const FACEBOOK_URL = 'https://www.facebook.com/accesimpotqc'
const TRACK_TARGETS = {
  email: 'email',
  phone: 'phone',
}

export default {
  name: 'Footer',
  components: {
    Heading,
    IconAccesImpot,
    IconFacebook,
    LineBreakResolver,
  },

  data() {
    return {
      FACEBOOK_URL,
      HEADINGS,
      INFO_EMAIL_ADDRESS,
      isViewLeaving: false,
      ROUTE_NAMES,
      TRACK_TARGETS,
    }
  },

  computed: {
    ...mapState('page', ['isVisible', 'isFooterVisible', 'isAccountSection']),

    // Needs to be a computed property to update on locale change
    linkToHomeFeatures() {
      return { path: this.localePath(ROUTE_NAMES.home), hash: '#steps' }
    },

    // Needs to be a computed property to update on locale change
    linkToHomePrices() {
      return { path: this.localePath(ROUTE_NAMES.home), hash: '#prices' }
    },
  },

  created() {
    EventBus.$on(EVENTS.viewLeave, this.onViewLeave)
    EventBus.$on(EVENTS.beforeViewEnter, this.beforeViewEnter)
  },

  beforeDestroy() {
    EventBus.$off(EVENTS.viewLeave, this.onViewLeave)
    EventBus.$off(EVENTS.beforeViewEnter, this.beforeViewEnter)
  },

  methods: {
    ...mapActions({
      showOverlayCookieConsent: 'overlay/showOverlayCookieConsent',
    }),

    goToHomeSection,

    onViewLeave() {
      this.isViewLeaving = true
    },

    beforeViewEnter() {
      this.isViewLeaving = false
    },

    getCurrentPathInOtherLanguage(language) {
      return this.$route.fullPath.replace(new RegExp(`^/?${this.$i18n.locale}/?`), `/${language}/`)
    },

    redirectToNewLanguage(newLanguage) {
      if (this.$i18n.locale === newLanguage) return

      window.location.href = this.getCurrentPathInOtherLanguage(newLanguage)
    },

    trackContactClick(target) {
      track(TRACK_CATEGORIES.contact, TRACK_CONTEXTS.footer, { target })
    },
  },
}
</script>

<style lang="scss" module>
@import '@/components/Heading/heading-settings';

$icon-acces-impot-width: 200px;
$column-head-height-full: $h4-font-size-full * $heading-line-height;
$column-head-height-medium: $h4-font-size-medium * $heading-line-height;
$column-head-height-small: $h4-font-size-small * $heading-line-height;

$item-color-text: rgba($color-white, 0.8);
$item-color-text-hover: rgba($color-white, 0.4);
$item-color-text-active: $color-brand-green;

.footer {
  background-color: $color-brand-navy;
  padding: 85px 60px 70px;
  color: $color-white;
  transition: opacity $transition-time-page-fade-in;
}

.leaving {
  transition-duration: $transition-time-page-fade-out;
  opacity: 0;
}

.footerInner {
  margin: 0 auto;
  max-width: 900px;
}

.footerColumns {
  display: block;
  position: relative;
  padding-bottom: 50px;
  border-bottom: 1px solid rgba($color-white, 0.4);
  width: 100%;

  @include min-screen($mq-large) {
    display: flex;
  }
}

.footerColumn {
  padding-right: 0;

  @include max-screen($mq-large) {
    &:nth-child(n + 2) {
      padding-top: 40px;
    }
  }

  @include min-screen($mq-large) {
    padding-right: calc(15% - 45px);
  }

  @include min-screen($mq-larger) {
    padding-right: 10%;
  }

  // to overwrite Heading styles
  .footerColumnHead {
    display: block;
    position: relative;
    white-space: nowrap;
    margin-bottom: 22px;
    height: $column-head-height-small;
    color: $color-white;
    cursor: default;

    @include min-screen($mq-medium) {
      height: $column-head-height-medium;
    }

    @include min-screen($mq-full) {
      height: $column-head-height-full;
    }
  }
}

.footerColumnHeadLogo {
  width: $icon-acces-impot-width;
}

.iconAccesImpot {
  position: absolute;
  top: calc(50% - 1px);
  left: -1px;
  width: $icon-acces-impot-width;
  vertical-align: top;
  transform: translateY(-50%);
}

.footerHeadLink {
  @include fake-padding-link;
  display: inline-block;
  width: 100%;
  min-height: $column-head-height-small;
  color: $color-white;

  @include min-screen($mq-medium) {
    min-height: $column-head-height-medium;
  }

  @include min-screen($mq-full) {
    min-height: $column-head-height-full;
  }

  &:global(.nuxt-link-exact-active) {
    cursor: default;
  }

  &:hover:not(:global(.nuxt-link-exact-active)) {
    opacity: 0.7;
  }
}

.footerItem,
.footerLinkWrap {
  padding-top: 6px;
  padding-bottom: 6px;
  color: $item-color-text;
}

.footerLink {
  @include fake-padding-link;

  &:hover:not(.activeLink):not(:global(.nuxt-link-exact-active)) {
    color: $item-color-text-hover;
  }
}

.footerLink:global(.nuxt-link-exact-active),
.activeLink {
  color: $item-color-text-active;
  cursor: default;
}

.footerItem {
  cursor: default;
}

.iconFacebookLink {
  position: absolute;
  top: -7px;
  right: 0;

  @include min-screen($mq-large) {
    top: 0;
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    box-sizing: border-box;
  }

  &:before {
    background-color: transparentize($color-white, 0.9);
    border-radius: 50%;
    width: 44px;
    height: 44px;
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.8);
    transition:
      opacity 0.2s,
      transform 0.2s ease-in-out;
  }

  &:after {
    width: 60px;
    height: 60px;
    transform: translate(-50%, -50%);
  }

  &:hover {
    &:before {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }
  }
}

.iconFacebook {
  height: 25px;
  vertical-align: top;
}

.copyright {
  margin-top: 15px;
  color: $item-color-text;
  font-size: $font-size-small;
}
</style>

<i18n src="@/locales/site-footer.json" />
